<template>
  <v-card class="elevation-0">
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
        v-on:keyup.enter="searchOnEnter"
        v-on:blur="searchOnEnter"
        append-icon="mdi-magnify"
        hint="Kode, Deskripsi (press ENTER to search)"
        label="Search"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="fDinCarouselsFiltered"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages = totalPaginationPages"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
            v-model="multiSelect"
            :label="multiSelect ? 'Multi Select' : 'Single Select'"
            x-small
            class="pa-3"
          ></v-switch>
          <v-btn
            fab
            dark
            color="red accent-4"
            x-small
            :disabled="!multiSelect"
            class="mr-2"
            @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            fab
            dark
            color="green"
            x-small
            @click="showDialogNew"
            :disabled="multiSelect"
            class="ml-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip :color="getColorStatusActive(item.statusActive)" dark small>
          {{ item.statusActive === true ? "Aktif" : "-" }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ index + (currentPage - 1) * pageSize + 1 }}
      </template>

      <template v-slot:[`item.avatar`]="{ item }">
        <v-img
          :lazy-src="lookupImageUrlLazy(item)"
          :src="lookupImageUrl(item)"
          alt="avatar"
          width="200px"
          height="100px"
          class="ma-2 rounded"
        >
        </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1">
          {{ item.description }}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.kode1">
          *{{ item.kode1 }}
        </div>
      </template>

      <template v-slot:[`item.fdinCarouselTypeBean`]="{ item }">
        <div class="fill-height mt-1">
          <div class="text-caption small">
            {{ lookupFDinCarouselType(item.fdinCarouselTypeBean).description }}
          </div>
          <div>
            {{ item.tahun }}
            <span v-if="item.showToMap"
              ><v-icon small color="blue">mdi-map</v-icon></span
            >
          </div>
        </div>
      </template>

      <template v-slot:[`item.fareaBean`]="{ item }">
        <div class="fill-height mt-1">
          <div class="text-caption small">
            {{ lookupFArea(item.fareaBean).description }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean).description }}
          </div>
          <div>
            <v-chip
              :color="getColorStatusActive(item.statusActive)"
              dark
              x-small
            >
              {{ item.statusActive === true ? "Aktif" : "-" }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="caption">
          {{ lookupFDivision(item.fdivisionBean).description }}
        </div>
        <div>
          <v-btn @click="showDialogEdit(item)" icon :disabled="multiSelect">
            <v-icon small color="warning"> mdi-pencil </v-icon>
          </v-btn>

          <v-btn @click="deleteDialogShow(item)" icon :disabled="multiSelect">
            <v-icon small color="red accent-4"> mdi-delete </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col cols="4" md="2" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per page"
          ></v-select>
        </v-col>
        <v-col cols="10" md="9" sm="8" align="right">
          <v-pagination
            v-model="currentPage"
            :length="totalPaginationPages"
            total-visible="8"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>
    <FDinCarouselDialog
      :formMode.sync="formMode"
      :itemsFDivision="itemsFDivision"
      :itemsFDinCarouselType="itemsFDinCarouselType"
      ref="refFormDialog"
      @eventFromFormDialogNew="saveDataNew"
      @eventFromFormDialogEdit="saveDataEdit"
    ></FDinCarouselDialog>

    <v-dialog width="100px" v-model="dialogLoading">
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import FDinCarouselService from "../../../services/apiservices/f-din-carousel-service";
import FDivisionService from "../../../services/apiservices/f-division-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

import FDinCarouselDialog from "./FDinCarouselDialog.vue";
import FormMode from "../../../models/form-mode";
import FDinCarousel from "../../../models/f-din-carousel";
import FileService from "../../../services/apiservices/file-service";
import FDinCarouselFilter from "@/models/payload/f-din-carousel-filter";

export default {
  components: {
    FDinCarouselDialog,
    DeleteConfirmDialog,
  },
  data() {
    return {
      title: "DINAMIC CAROUSEL",
      snackbar: false,
      snackBarMesage: "",

      dialogLoading: false,
      loading: false,

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500, 1500],

      showFilter: false,
      filterFdivisions: [],
      filterFdinCarouselType: [],
      filterFareaBean: [],

      search: "",
      headers: [
        {
          text: "No",
          value: "number",
          width: "8%",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "avatar",
        },
        { text: "Judul", value: "description", width: "50%" },
        { text: "Jenis", value: "fdinCarouselTypeBean" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      fDinCarousels: [new FDinCarousel(0, "", "")],
      itemsFDivision: [{ id: 0, kode1: "", description: "" }],
      itemsFDinCarouselType: [
        {id:1, description:"Home", descriptionEn:"Home"},
      ],
      itemsFArea: [],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFDinCarousel();
      },
    },
    pageSize: {
      handler: function (value) {
        const refreshData = this.currentPage === 1;
        this.currentPage = 1;
        if (refreshData) {
          console.log("Change PageSize " + value);
          this.fetchFDinCarousel();
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      },
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    fDinCarouselsFiltered() {
      return this.fDinCarousels;
    },
  },
  methods: {
    runExtendedFilter() {
      const extendedFilter = new FDinCarouselFilter();

      if (this.filterFdivisions.length > 0) {
        extendedFilter.fdivisionIds = this.filterFdivisions;
      } else {
        extendedFilter.fdivisionIds = this.filterFdivisions.map(
          (item) => item.id
        );
      }

      if (this.filterFdinCarouselType.length > 0) {
        extendedFilter.fdinCarouselTypeIds = this.filterFdinCarouselType;
      } else {
        extendedFilter.fdinCarouselTypeIds = this.itemsFDinCarouselType.map(
          (item) => item.id
        );
      }
      extendedFilter.fareaIds = this.filterFareaBean;
      if (extendedFilter.fareaIds.length === 0) extendedFilter.fareaIds = [];

      extendedFilter.pageNo = this.currentPage;
      extendedFilter.pageSize = this.pageSize;
      extendedFilter.sortBy = "id";
      extendedFilter.order = "DESC";
      extendedFilter.search = this.search;

      extendedFilter.city = "";

      // console.log(JSON.stringify(extendedFilter));

      FDinCarouselService.getPostAllFDinCarouselContainingExt(
        extendedFilter
      ).then(
        (response) => {
          const { items, totalPages } = response.data;
          this.fDinCarousels = items;
          this.totalPaginationPages = totalPages;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    searchOnEnter(event) {
      if (this.search !== event.target.value) {
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFDinCarousel();
      }
    },

    importExcelCompleted(value) {
      if (value === "OKE") {
        this.fetchFDinCarousel();
      }
    },
    fetchParent() {
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
            // console.log(response.data.items)
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            // console.log(JSON.stringify(response.data))
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

    },
    fetchFDinCarousel() {
      if (this.showFilter) {
        this.runExtendedFilter();
      } else {
        FDinCarouselService.getAllFDinCarouselContaining(
          this.currentPage,
          this.pageSize,
          "id",
          "DESC",
          this.search
        ).then(
          (response) => {
            const { items, totalPages } = response.data;
            this.fDinCarousels = items;
            this.totalPaginationPages = totalPages;

            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          (error) => {
            console.log(error.response);
          }
        );
      }
    },
    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },
    //DUAL BAHASA
    showDialogNew() {
      this.itemSelectedIndex = -1;
      const itemModified = Object.assign({}, "");
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM;
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified);
    },
    saveDataNew(itemFromRest) {
      this.itemSelected = itemFromRest;
      this.closeDialog();
      /**
       * Setiap data baru harus terlihat
       */
      // this.fDinCarousels.push(this.itemSelected)
      // this.fDinCarousels = [itemFromRest].concat(this.fDinCarousels)
      // this.fDinCarousels.unshift(itemFromRest)
      this.fetchFDinCarousel();
    },

    showDialogEdit(item) {
      this.itemSelectedIndex = this.fDinCarouselsFiltered.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFormDialog.showDialog(
        this.itemSelectedIndex,
        itemModified,
        this.itemsFDivision
      );
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      this.fetchFDinCarousel();

      this.closeDialog();
    },

    deleteDialogShow(item) {
      // console.log(item)
      this.itemSelectedIndex = this.fDinCarouselsFiltered.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.username
      );
    },
    deleteDialogMultiShow() {
      if (this.multiSelect === true) {
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(
          this.selectedItems,
          `${this.selectedItems.length} items selected`
        );
      }
    },
    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.fDinCarousels[this.itemSelectedIndex];
      FDinCarouselService.deleteFDinCarousel(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fDinCarousels.splice(this.itemSelectedIndex, 1);
          this.closeDialog();
        },
        (error) => {
          console.log(error);
          this.snackBarMesage = "gagal hapus (digunakan oleh data anakk)";
          this.snackbar = true;
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      // console.log(okeMessage)

      if (items.length > -1) {
        let itemIds = [];
        for (let i = 0; i < items.length; i++) {
          itemIds.push(items[i].id);
        }

        // console.log("Item idex: " + itemIds)

        FDinCarouselService.deleteAllFDinCarousel(itemIds).then(
          (response) => {
            console.log(response.data);

            if (this.showFilter) {
              this.runExtendedFilter();
            } else {
              this.fetchFDinCarousel();
            }
            this.selectedItems = [];
            this.closeDialog();
          },
          (error) => {
            console.log("error " + error);
          }
        );

        // console.log(items)
        // FDinCarouselService.deleteAllFDinCarousels()
      }
    },
    closeDialog() {
      // this.myConfirmDialog = false
      this.formMode = "";
      this.$refs.refDeleteConfirmDialog.setDialogState(false);
      this.$refs.refFormDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FDinCarousel());
        this.itemSelectedIndex = -1;
      });
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFDinCarouselType(finfratructureTypeBean) {
      const str = this.itemsFDinCarouselType.filter(
        (x) => x.id === finfratructureTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_low(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.fetchParent();
      this.fetchFDinCarousel();
    }
  },
};
</script>

<style scoped>
</style>